import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import CustomProjectCard from "./custom-project-card";

import "../styles/overlapped-project-cards.less";


const OverlappedProjectCards = ({ intl }) =>
(
    <div className="overlapped-project-cards">
        <div className="row justify-content-center">
            <div className="col-lg-4 left-card">
                <CustomProjectCard image={{name: "investment-round-kolaboo.png", alt: "Kolaboo"}} project={{title: "Kolaboo", sector: "Turismo", description: intl.formatMessage({id: "Kolaboo is the comparator of tours and tourist activities. We help travelers find the best option at the best price for everything they can do at the destination they visit. We are the 'Skyscanner' of excursions, tours..."}), author: {avatar: "investment-round-kolaboo-avatar.png", name: "The Global Sharing Company SL"}}}/>
            </div>
            <div className="col-lg-4 center-card">
                <CustomProjectCard image={{name: "investment-round-tripleo.png", alt: "Triple O Games"}} project={{title: "Triple O Games", sector: "Audiovisual y videogaming", description: intl.formatMessage({id: "Triple O proposes a new formula of monetization in gaming based on blockchain and tokenization of digital assets. Through a new business model 'Play2earn', Triple O allows players to monetize their skill."}), author: {avatar: "investment-round-tripleo-avatar.png", name: "Triple O"}}}/>
            </div>
            <div className="col-lg-4 right-card">
                <CustomProjectCard image={{name: "investment-round-blogsterapp.png", alt: "Blogsterapp"}} project={{title: "Blogsterapp", sector: "Internet", description: intl.formatMessage({id: "BlogsterApp Ambassador is a SaaS (Software as a Service) platform that allows the massive, automatic and totally segmented dissemination of any corporate content through employees' social networks."}), author: {avatar: "investment-round-blogsterapp-avatar.png", name: "BLOGSTERAPP AMBASSADOR, S.L."}}}/>
            </div>
        </div>
    </div>
)

export default injectIntl(OverlappedProjectCards);

import React from 'react';
import PropTypes from 'prop-types';

import {StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Button from "./button";
import "../styles/banner.less";

const Banner = (props) => (
    <StaticQuery
        query={graphql`
            query {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        `}

        render={(data) => {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(props.filename);
            });
            if (!image) { return null; }
        
            return (
                <BackgroundImage
                fluid={image.node.childImageSharp.fluid}
                className={props.className}
                loading="eager"
                fadeIn={false}
                >
                    <div className="overlay"></div>
                    <div className = "container">
                        <div className="row align-items-center justify-content-start">
                            <div className={props.colSize ? 'col-sm-12 '+props.colSize : 'col-sm-12 col-md-6'}>
                                {props.dangerous && props.dangerous.title && <h1 dangerouslySetInnerHTML={{__html: props.title}}></h1>}
                                {(!props.dangerous || !props.dangerous.title) && <h1>{props.title}</h1>}

                                {props.dangerous && props.dangerous.subtitle && <p dangerouslySetInnerHTML={{__html: props.subtitle}}></p>}
                                {(!props.dangerous || !props.dangerous.subtitle) && <p className="banner-subtitle">{props.subtitle}</p>}

                                {props.button &&
                                    <div className="main-call-to-action">
                                        <Button text={props.button.text} url={props.button.url} note={props.button.note} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            );
        }}
    />
)

Banner.propTypes = {
    filename: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,

    // This field is optional, and is meant to be used when the title or the subtitle (the one you pass within this object) have HTML that needs to be rendered as such.
    // For example, if the subtitle is "Hello, world!", you just use the 'subtitle' attribute. But if it is "<strong>Hello</strong, <a>world!</a>", you use dangerous.subtitle
    dangerous: PropTypes.shape({
        title: PropTypes.bool,
        subtitle: PropTypes.bool
    }),

    // If the banner needs a button (like the page business-up), you use this object. The 'note' parameter is optional, it renders the contents bellow the button. (same, see busines-up banner)
    button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        note: PropTypes.string
    })
  };

export default Banner;
import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Section from "../components/section";
import Banner from "../components/banner";
import OverlappedProjectCards from "../components/overlapped-project-cards";
//import CampaignItemBig from "../components/template/campaign-item-big";
import Button from "../components/button";

import "../styles/investment.less";
import CampaignItem from "../components/template/campaign-item";


const Investment = (props) => {
  return <Layout>
    <SEO 
		title={props.intl.formatMessage({id: "Access to Investment - Adventurees Alliance"})} 
		description={props.intl.formatMessage({id: "Access our own investment platform, Adventurees, and Investment Forums and Clubs with our network of investors and our partners"})}
		location={props.location}
		image="/images/sharing/home-investment.jpg"
	/>

    <Banner 
        className="banner" 
        filename="investment-banner.png"
        title={props.intl.formatMessage({id: "Connect with our network of investors"})}
        subtitle={props.intl.formatMessage({id: "Rely on our network to co-finance your business"})}
    />

    {/*<Section>
      <CampaignItemBig image={{name: "investment-pfp.png", alt:"PFP"}} campaign={{category: props.intl.formatMessage({id: "Platform authorized by the CNMV to channel investment"}), title: props.intl.formatMessage({id: "INVESTMENT PLATFORM"}), description: props.intl.formatMessage({id: "Adventurees Group has an investment platform through which it makes the best projects to be known to potential investors. At the same time, in order to make investments easier, it is also in charge of legally conducting operations, providing greater security to companies and investors."}) + "<br /><br />" + props.intl.formatMessage({id: "The company that operates this investment platform, <a href='https://www.adventurees.com' target='_blank'>Adventurees</a> is an entity authorised by the National Securities Market Commission and the Bank of Spain to channel investments and loans through the Internet. The company is registered in the Official Register of Authorised Entities under Number 14."})}} />
    </Section>*/}

    <Section>
		<div className="row align-items-center page-introduction">
			<div className="col-md-6 introduction-left clients-left">
				<p>{props.intl.formatMessage({id: "Platform authorized by the CNMV"})}</p>
				<h3>{props.intl.formatMessage({id: "INVESTMENT PLATFORM"})} <a href="https://www.adventurees.com">ADVENTUREES.COM</a></h3>
			</div>
			<div className="col-md-6 introduction-right clients-right">
				<p>{props.intl.formatMessage({id: "Adventurees Group has an investment platform through which it makes the best projects to be known to potential investors. At the same time, in order to make investments easier, it is also in charge of legally conducting operations, providing greater security to companies and investors."})}</p>
				<p dangerouslySetInnerHTML={{__html: props.intl.formatMessage({id: "The company that operates this investment platform, <a href='https://www.adventurees.com' target='_blank'>Adventurees</a> is an entity authorised by the National Securities Market Commission and the Bank of Spain to channel investments and loans through the Internet. The company is registered in the Official Register of Authorised Entities under Number 14."})}}></p>
			</div>
		</div>
	</Section>

    <Section className="investment-section" title={props.intl.formatMessage({id: "Investment rounds"})}>
     	<OverlappedProjectCards />
		<Button className="investment-button" text="View projects" url="https://www.adventurees.com/static/invest/" target="_blank"/>
    </Section>

    <Section id="investment-clubs" title={props.intl.formatMessage({id: "Join our investors network"})}>
		<div className="row" style={{marginBottom: "20px"}}>
			<div className="col-lg-4 col-md-6">
				<CampaignItem image={{name: 'investment-club-investors.png', alt: props.intl.formatMessage({id: 'Investment clubs'})}} campaign={{title: props.intl.formatMessage({id: "Investment clubs"}), description: props.intl.formatMessage({id: "We have investment clubs that are verticalized by interest, such as Fintech or Blockchain. All of them benefit from our deal flow of opportunities and the application of our investment tools."})}} />
			</div>
			<div className="col-lg-4 col-md-6">
				<CampaignItem image={{name: 'investment-club-demoday.png', alt: 'DemoDay'}} campaign={{title: "DemoDay", description: props.intl.formatMessage({id: "On our Demo Days we present the best investment opportunities to our network of investors. It is the perfect opportunity to meet the companies' CEOs in person and increase the network of contacts with other investors."})}} />
			</div>
			<div className="col-lg-4 col-md-6">
				<CampaignItem
					image={{name: 'investment-club-forum.png', alt: props.intl.formatMessage({id: 'FINTECH TEXAS FORUM - SPAIN 2020'})}}
					campaign={{title: props.intl.formatMessage({id: "FINTECH TEXAS FORUM - SPAIN 2020"}), description: props.intl.formatMessage({id: "Adventurees brings together companies and investors from USA, Spain and LATAM to present the most interesting companies in the Fintech sector. A unique opportunity to know first hand the trends of the moment and to invest together with our international network of investors in the most innovative companies."}),}} 
				/>
			</div>
		</div>  
    </Section>

  </Layout>
}

export default injectIntl(Investment);
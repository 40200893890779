import React from "react";
import {injectIntl } from 'gatsby-plugin-intl';
import Image from '../image';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../button';
import "../../styles/template/campaign-item.less";

/**
 * 
 * @param {Object} image object with image properties (image.name, image.alt)
 * @param {Object} campaign object with campaign details (campaign.title, campaign.category, campaign.description, campaign.staffPicksAuthor (.profile.avatar, profile.name))
 */

const CampaignItemBig = ({intl, image, campaign, overlay}) => (
    <div className="campaign-item">
        {overlay &&
            <a className="overlay" href="javascript:void(0);">
                <Image filename={image.name} alt={image.alt} />
                <span><FontAwesomeIcon icon={faSearch} /></span>
            </a>
        }
        {!overlay && <Image filename={image.name} alt={image.alt} />}
        {campaign &&
            <div className="campaign-box">
                {campaign.category && <a className="category" href="javascript:void(0);">{campaign.category}</a>}
                {campaign.title && <h3><a href="javascript:void(0);">{campaign.title}</a></h3>}
                {campaign.description && <div className="campaign-description">{campaign.description} {campaign.descriptionLinkInline && <a href={campaign.descriptionLinkInline.link} target="_blank" rel="noopener noreferrer">{campaign.descriptionLinkInline.text}</a>}</div>}
                {campaign.author && <div className="campaign-author">
                    {campaign.author.icon && <a className="author-icon" href="javascript:void(0);">
                            <Image filename={campaign.author.icon} />
                        </a>}
                    {campaign.author.name && <a className="author-name" href="javascript:void(0);">{campaign.author.name}</a>}
                </div>}
                {campaign.list &&
                    <ul>
                        {campaign.list.map((element, index) => {
                            return <li key={index}>{element}</li>
                        })}
                    </ul>
                }
                {campaign.button &&
                    <Button className="campaign-button" text={campaign.button.text} internalUrl={campaign.button.url} />
                }
            </div>
        }
    </div>
)

export default injectIntl(CampaignItemBig);

import React from "react";
import PropTypes from 'prop-types';

import Image from "./image";

/**
const image={
    name: String
    alt: String
};

const project={
    title: String
    sector: String
    description: String
    author: {
        avatar: String
        name: String
    }
}
*/
const CustomProjectCard = (props) =>
(
    <div className="project-custom-card">
        <div className="project-card-image-container">
            <Image filename={props.image.name} alt={props.image.alt} />
        </div>
        <div className="project-box">
            <h3 className="project-title">{props.project.title}</h3>
            <p className="project-sector">{props.project.sector}</p>
            <div className="project-description">
                {props.project.description}
            </div>

            <div className="project-author">
                <span className="author-avatar">
                    <Image filename={props.project.author.avatar} alt={props.project.author.name} />
                </span>
                <p className="author-name">Por {props.project.author.name}</p>
            </div>
        </div>
    </div>
)

CustomProjectCard.propTypes = {
    image: PropTypes.shape({
        name: PropTypes.string.isRequired,
        alt: PropTypes.string
    }).isRequired,

    project: PropTypes.shape({
        title: PropTypes.string.isRequired,
        sector: PropTypes.string.isRequired,
        description: PropTypes.string.description,
        author: PropTypes.shape({
            avatar: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
}

export default CustomProjectCard;
